import {
  LOGIN_PAGE,
  getStoredSessionToken,
  redirectWithPath,
  clearSession,
  IG_APP,
} from "/@lib/shared"
import { twApiClient } from "/@lib/tastyworks-rest"

export function logout() {
  const token = getStoredSessionToken()
  return twApiClient.sessionService.logout(token).then(() => {
    clearSession()
    window.dataLayer?.push({ event: "customer_logout" })

    if (IG_APP) {
      window.open(import.meta.env.VITE_MY_IG_URL, "_self")
    } else {
      redirectWithPath(LOGIN_PAGE)
    }
  })
}
